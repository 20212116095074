<template>
    <div :class="['icon-wrapper', backgroundCss]" >
        <div class="svg-wrapper">
            <font-awesome-icon :icon="[iconType, iconName]"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserIcon",
    props: {
        iconType: {
            type: String,
            required: true,
        },
        iconName: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            required: true,
        }
    },
    computed: {
        backgroundCss() {
          if(this.backgroundColor === 'primary')
              return 'primary-bg';
          if(this.backgroundColor === 'secondary')
              return 'secondary-bg';
        },
    },
}
</script>

<style lang="scss" scoped>
    .icon-wrapper{
        @apply flex rounded-full h-7 w-7;

        &.primary-bg{
            @apply bg-primary;
        }

        &.secondary-bg{
            @apply bg-secondary;
        }

        .svg-wrapper{
            @apply content-center justify-center my-auto mx-auto fill-current text-black opacity-40;

            svg{
                @apply w-2.5;
            }
        }
    }

</style>